import React, { useState, useEffect, useCallback } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { useMsal } from '@azure/msal-react'
import { logoutRequest } from 'Config/mfa-config'
import classNames from 'classnames'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ReactComponent as HistoryIcon } from 'Assets/Icons/History.svg'
import { ReactComponent as SwitchOnIcon } from 'Assets/Icons/SwitchOn.svg'
import { ReactComponent as CompassCenterIcon } from 'Assets/Icons/CompassCenter.svg'
import { ReactComponent as ArrowLeftIcon } from 'Assets/Icons/ArrowLeft.svg'
import { ReactComponent as ComplaintIcon } from 'Assets/Icons/Complaint.svg'
import { ReactComponent as RequestIcon } from 'Assets/Icons/Request.svg'
import { ReactComponent as ApprovedRequestButton } from 'Assets/Icons/RequestToApprove.svg'
import { ReactComponent as ProviderIcon } from 'Assets/Icons/Proveedor.svg'
import { ModalCenter, ApproverTutorialBanner } from 'Components/molecules'
import { PurchaseTypes } from 'Redux/purchases'
import { AuthActions, AuthTypes } from 'Redux/auth'
import { TicketActions } from 'Redux/ticket'
import { selectTicketsPendingListCount } from 'Redux/ticket/selectors'
import { ROLES, TUTORIAL_NAMES } from 'Config/constants'
import routes from 'Config/routes'
import { needsApprovalMode } from 'Redux/approver/selectors'
import { ApproverActions } from 'Redux/approver'
import { CartsActions } from 'Redux/carts'
import { fetchDeliveryErrors } from 'Redux/delivery/actions'
import { isUserRoleApprover } from 'Components/utils/roles'
import { checkTutorialBanner /*, launchTutorial */ } from 'Redux/tutorial/actions'
import { selectConfig } from 'Redux/auth/utils'
import { hasPendingToReceiveOrders } from 'Components/utils/order'

import ApproverNavBar from '../ApproverNavBar'
import ImpersonatedNavBar from '../ImpersonatedNavBar'

import MulticenterButtons from './multicenterButtons'
import { useStyles, Container, StyledChip, OrderPRCounter, Badge, Topbar, RestartButton, OrderPRBullet } from './styled'

export default () => {
  const isApproverMatch = useRouteMatch({ path: '/approver' })
  const needsApprovalCheckout = useRouteMatch({ path: routes.approvedCheckout })

  const needsApproval = useSelector(needsApprovalMode)

  if (needsApprovalCheckout) return <ImpersonatedNavBar showBanner={false} showAppBar={false} showBack />

  if (needsApproval) return <ImpersonatedNavBar showBanner showAppBar showBack />

  if (isApproverMatch) return <ApproverNavBar />

  return <ButtonAppBar />
}

const ButtonAppBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const hidden = useSelector(state => state.auth.hiddenNavbar)
  const center = useSelector(({ auth }) => auth.selectedCenter)
  const user = useSelector(state => state.auth.user)
  const hasOnlyOneCenter = useSelector(({ auth }) => auth.hasOnlyOneCenter)
  const features = useSelector(selectConfig)

  const numProviderPendingOrders = useSelector(state => state.purchase.numProviderPendingOrders)
  const numPendingTicketsCount = useSelector(selectTicketsPendingListCount)
  const failedReceptions = useSelector(state => state.purchase.failedReceptions)

  const deliveryErrors = useSelector(state => state.delivery.deliveryErrors)

  const [showModal, setShowModal] = useState(false)

  const isApprover = isUserRoleApprover(user)

  const isTutorialEnabled = features?.tutorial
  const isMulticenter = features?.multicenter

  useEffect(() => {
    if (!isEmpty(center)) {
      dispatch({
        type: PurchaseTypes.FETCH_PENDING_PROVIDER_ORDERS,
      })

      dispatch(TicketActions.fetchPendingTickets())
    }
  }, [center, dispatch])

  useEffect(() => {
    if (features?.tutorial) {
      dispatch(checkTutorialBanner(TUTORIAL_NAMES.request))
    }
    if (features?.order_reception_sqs) {
      dispatch(fetchDeliveryErrors())
    }
  }, [dispatch, features])

  useEffect(() => {
    dispatch({
      type: PurchaseTypes.FETCH_FAILED_RECEPTIONS,
      params: {
        endDate: moment().add(2, 'day').startOf('day').format(),
        startDate: moment().subtract(1, 'year').startOf('day').format(),
      },
    })
  }, [dispatch])

  const onCenterClick = () => {
    if (hasOnlyOneCenter) return
    setShowModal(true)
  }

  const showBack = location.pathname === routes.checkout || location.pathname === routes.orderQa

  const role = find(user.roles, elem => elem !== 'ROLE_USER')

  const hasCenter = !isEmpty(center)
  const isMulticenterRoute = [routes.multicenterOrders, routes.multicenterTicketing].includes(location.pathname)

  return (
    <Container maxHeight={hidden ? 0 : '200px'} className={classes.root}>
      {isTutorialEnabled && (isApprover ? <ApproverTutorialBanner /> : <RequestTutorialBanner />)}
      <CustomAppBar>
        <Toolbar>
          {showBack ? (
            <BackMenuButton />
          ) : (
            <>
              {!hasCenter && isMulticenterRoute && (
                <MulticenterButtons isApprover={!!isApprover} onCenterClick={onCenterClick} />
              )}
              {hasCenter && <CenterMenuButton onClick={onCenterClick} center={center} />}
              {hasCenter && (
                <HistoryMenuButton
                  badge={numProviderPendingOrders || !isEmpty(deliveryErrors) || !isEmpty(failedReceptions)}
                />
              )}
              {hasCenter && ROLES.ROLE_STEF !== ROLES[role] && <TicketsMenuButton badge={numPendingTicketsCount} />}
              {hasCenter && center?.requiresApproval && <CartsMenuButton />}
              {hasCenter && isApprover && <PendingRequestsButton />}
              {hasCenter && isMulticenter && !hasOnlyOneCenter && <MulticenterButton />}
            </>
          )}
          <MenuItemSeparator />
          <LogoutMenuButton />
          {user && <UserInfo />}
        </Toolbar>
      </CustomAppBar>
      <ModalCenter showModal={showModal} setShowModal={setShowModal} />
    </Container>
  )
}

const CustomAppBar = ({ children }) => {
  const classes = useStyles()

  const location = useLocation()

  return (
    <AppBar
      style={{ borderRadius: location.pathname === routes.home ? '4px' : '' }}
      position='static'
      color='default'
      className={classes.bar}
    >
      {children}
    </AppBar>
  )
}

CustomAppBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

CustomAppBar.defaultProps = {
  children: null,
}

const BackMenuButton = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const goToMarket = () => {
    history.push(routes.purchase)
  }

  const showDatesUpdated = useSelector(state => state.purchase.datesUpdated)

  return (
    <div>
      <Button startIcon={<ArrowLeftIcon />} onClick={goToMarket} className={classes.menuButton} color='inherit'>
        {t('goToMarket')}
      </Button>
      {showDatesUpdated && <StyledChip label={t('datesUpdated')} color='primary' />}
    </div>
  )
}

BackMenuButton.defaultProps = {}

BackMenuButton.propTypes = {}

export const CenterMenuButton = ({ onClick, center }) => {
  const classes = useStyles()

  return (
    <Button onClick={onClick} startIcon={<CompassCenterIcon />} className={classes.menuButton} color='inherit'>
      {`${center.name} - ${center.id}`}
    </Button>
  )
}

CenterMenuButton.defaultProps = {
  onClick: () => {},
  center: {},
}

CenterMenuButton.propTypes = {
  onClick: PropTypes.func,
  center: PropTypes.object,
}

const HistoryMenuButton = ({ badge }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const goToHistoric = () => {
    history.push(routes.historic)
  }

  return (
    <Button
      onClick={goToHistoric}
      startIcon={<HistoryIcon />}
      className={classes.menuButton}
      color='inherit'
      data-cy='history-nav'
    >
      {t('history')}
      {/* // TODO PendingToReceive: restore <OrderPrCounter /> and delete <OrderPRBullet /> when PendingToReceive EP is ready */}
      {hasPendingToReceiveOrders(badge) && <OrderPRBullet />}
      {/* {hasPendingToReceiveOrders(badge) && <OrderPRCounter>{badge}</OrderPRCounter>} */}
    </Button>
  )
}

HistoryMenuButton.defaultProps = {
  badge: 0,
}

HistoryMenuButton.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const TicketsMenuButton = ({ badge }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const goToTicketing = () => {
    history.push(routes.ticketingList)
  }

  return (
    <Button
      onClick={goToTicketing}
      startIcon={<ComplaintIcon />}
      className={classes.menuButton}
      color='inherit'
      data-cy='ticketing-nav'
    >
      {t('complaints')}
      {parseInt(badge, 10) > 0 && <OrderPRCounter>{badge}</OrderPRCounter>}
    </Button>
  )
}

TicketsMenuButton.defaultProps = {
  badge: 0,
}

TicketsMenuButton.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const CartsMenuButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const badge = useSelector(state => state.carts.pendingApprovalCount || 0)

  const goToCarts = () => {
    history.push(routes.carts)
  }

  useEffect(() => {
    dispatch(CartsActions.fetchPendingApproval())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button
      aria-label={t('requestTutorial.stepFour.target')}
      onClick={goToCarts}
      startIcon={<RequestIcon />}
      className={classes.menuButton}
      color='inherit'
      data-cy='requests-nav'
    >
      {t('requests')}
      {parseInt(badge, 10) > 0 && <OrderPRCounter>{badge}</OrderPRCounter>}
    </Button>
  )
}

CartsMenuButton.defaultProps = {}

CartsMenuButton.propTypes = {}

const MenuItemSeparator = () => {
  const classes = useStyles()
  return <div className={classes.title} />
}

const UserInfo = () => {
  const classes = useStyles()

  const user = useSelector(state => state.auth.user)

  const role = find(user.roles, elem => elem !== 'ROLE_USER')

  return (
    <>
      <span className={classes.username}>{user.username}</span>
      {ROLES[role] && <span className={classes.role}>{ROLES[role]}</span>}
    </>
  )
}

const LogoutMenuButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { instance } = useMsal()

  const user = useSelector(state => state.auth.user)

  const logout = async () => {
    try {
      dispatch({
        type: AuthTypes.LOGOUT_USER,
      })
      if (user.msal) {
        await instance.logout(logoutRequest)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <IconButton
      color='inherit'
      onClick={logout}
      style={{ textTransform: 'none ' }}
      className={classes.logout}
      data-cy='logout'
    >
      <SwitchOnIcon />
    </IconButton>
  )
}

LogoutMenuButton.defaultProps = {}

LogoutMenuButton.propTypes = {}

const PendingRequestsButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const badge = useSelector(state => state.approver.pendingApprovalCount || 0)

  const goToTicketing = () => {
    history.push(routes.approverCarts)
  }

  useEffect(() => {
    dispatch(ApproverActions.fetchPendingApproval())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button
      onClick={goToTicketing}
      startIcon={<ApprovedRequestButton />}
      className={classes.menuButton}
      color='inherit'
      data-cy='pending-request-nav'
    >
      {t('approveRequests')}
      {parseInt(badge, 10) > 0 && <Badge>{badge}</Badge>}
    </Button>
  )
}

const MulticenterButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const goToMulticenter = useCallback(() => {
    dispatch(AuthActions.setCenter({}))
    history.push(routes.multicenterOrders)
  }, [history, dispatch])

  return (
    <Button
      onClick={goToMulticenter}
      startIcon={<ProviderIcon />}
      className={classNames(classes.menuButton, 'my-centers-nav')}
      color='inherit'
      data-cy='my-centers-nav'
    >
      {t('myCenters')}
    </Button>
  )
}

const RequestTutorialBanner = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    window.open(process.env.REACT_APP_REQUEST_TUTORIAL_URL, '_blank')
  }

  return (
    <Topbar>
      <p>
        {t('newFunctionality')} <strong>{`"${t('requests')}"`} </strong>
        {t('nowApprovedRequest')}
      </p>
      <RestartButton type='button' onClick={handleClick}>
        <strong>{t('howItWorks')}</strong>
      </RestartButton>
    </Topbar>
  )
}
